import React from 'react';
import './App.css';
import * as qs from "query-string";
import {connect} from "react-redux";
import {addAuthToken, removeAuthToken} from "./store/actions/authToken";
import {Landing} from "./screens/Landing";
import LoggedIn from "./screens/LoggedIn";


function App(props) {
    /**
     * This is the main component. It looks if an auth_token was provided from the OAuth redirect (from our back-end)
     * and saves it to the redux store (with the valid until timestamp). Afterwards, the url is cleaned for security
     * reasons (we don't wanna expose the auth token to the "public" in the url).
     */

    if (qs.parse(props.router.location.search, {ignoreQueryPrefix: true}).auth_token !== undefined) {
        props.addAuthToken(
            {
                token: qs.parse(props.router.location.search, {ignoreQueryPrefix: true}).auth_token,
                valid_until: qs.parse(props.router.location.search, {ignoreQueryPrefix: true}).valid_until
            });
        props.history.replace({pathname: "/"})
    }

    if (props.authToken.valid_until && props.authToken.valid_until * 1000 <= Date.now()) {
        // log out if auth token is no longer valid
        props.logOut()
    }

    return (
        <div className="App bg">
            {props.authToken.token ?
                <LoggedIn/>
                :
                <Landing/>
            }
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    addAuthToken: token_obj => dispatch(addAuthToken(token_obj)),
    logOut: () => dispatch(removeAuthToken())
});

const mapStateToProps = (state) => {
    return state
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
