import React from "react";
import './../App.css';


function openPageController() {
    window.open(process.env.REACT_APP_SLACK_OAUTH_URL, "_self");
}

export function Landing() {
    return (
        <div className="container content">
            <img src={require("./../assets/images/sign_in_with_slack_button.png")} className="slack-button"
                 onClick={() => openPageController()} alt={"Sign In with Slack"}/>
        </div>
    )
}