const users = (state = {}, action) => {
    switch (action.type) {
        case 'USERS_DATA_FETCH_SUCCESS':
            return {
                list: action.data
            };
        case 'USERS_DATA_IS_LOADING':
            return {
                ...state,
                isLoading: action.isLoading,
                hasErrored: false
            };

        case 'USERS_DATA_HAS_ERRORED':
            return {
                ...state,
                hasErrored: action.hasErrored,
                isLoading: false,
                errorDetails: action.errorDetails
            };
        default:
            return state
    }
};


export default users
