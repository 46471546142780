import React from 'react';
import {Button} from 'react-bootstrap';
import axios from 'axios';
import "./../App.css";
import ActiveUsers from "../components/subscreens/ActiveUsers";
import {connect} from "react-redux";
import {removeAuthToken} from "../store/actions/authToken";

class LoggedIn extends React.Component {

    openDoor(door_slug) {
        let url = process.env.REACT_APP_BACKEND_URL + "rest/v0.1/doors/" + door_slug + "/";
        let token = this.props.authToken.token;
        axios.post(url, {}, {headers: {"Authorization": `Token ${token}`}})
            .then(function () {
                alert("Bitte klingle jetzt an der Tür. Dafür hast du 30 Sekunden Zeit, danach musst du es erneut probieren!")
            })
            .catch(function (error) {
                alert("An error occurred: " + error.message)
            })

    }


    render() {
        return (
            <div>
                <div className="button"><Button variant="light"
                                                onClick={() => this.openDoor("senktor")}>Senktor</Button>
                </div>
                <div className="button"><Button variant="light"
                                                onClick={() => this.openDoor("treppenhaus")}>Treppenhaus</Button></div>
                <div className="button"><Button variant="light"
                                                onClick={() => this.openDoor("etagentuer")}>Etagentür</Button></div>
                <div className="button"><Button variant="danger"
                                                onClick={() => this.props.logOut()}>Ausloggen</Button></div>

                <ActiveUsers/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(removeAuthToken())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn)