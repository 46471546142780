import React from "react";
import {Toast} from "react-bootstrap";
import {getActiveUsers} from "../../store/actions/users";
import {connect} from "react-redux";

class ActiveUsers extends React.Component {

    componentDidMount() {
        this.props.getActiveUsers();
    }

    timeDifference(previous) {
        // some function found on Stack Overflow

        let current = Date.now();
        previous = previous * 1000;

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return 'Vor ' + Math.round(elapsed / 1000) + ' Sekunden';
        } else if (elapsed < msPerHour) {
            if (Math.round(elapsed / msPerMinute) === 1) {
                return 'Vor ' + Math.round(elapsed / msPerMinute) + ' Minute';
            }
            return 'Vor ' + Math.round(elapsed / msPerMinute) + ' Minuten';
        } else if (elapsed < msPerDay) {
            if (Math.round(elapsed / msPerHour) === 1) {
                return 'Vor ' + Math.round(elapsed / msPerHour) + ' Stunde';
            }
            return 'Vor ' + Math.round(elapsed / msPerHour) + ' Stunden';
        } else if (elapsed < msPerMonth) {
            return 'Vor ' + Math.round(elapsed / msPerDay) + ' Tagen';
        } else if (elapsed < msPerYear) {
            return 'Vor' + Math.round(elapsed / msPerMonth) + ' Monaten';
        } else {
            return 'Vor ' + Math.round(elapsed / msPerYear) + ' Jahren';
        }
    }

    render() {
        return (
            <div>
                {this.props.users.list && this.props.users.length > 0 ?
                    <div style={{position: "absolute", bottom: 0, width: "100%", backgroundColor: "#153041"}}>
                        <div style={{position: "relative", overflow: "auto", maxHeight: 300}}>
                            <p style={{color: "white", paddingTop: 10}}>
                                Diese Hive'ler haben in den letzten 6 Stunden die Tür geöffnet:
                            </p>
                            <Toast>
                                {this.props.users.list.map((user_obj) =>
                                    <Toast.Header closeButton={false}>
                                        <img src={user_obj.user.slack_avatar_url ? user_obj.user.slack_avatar_url :
                                            require("../../assets/images/anonymous-user.png")
                                        }
                                             style={{maxHeight: 25}} className="rounded mr-2"
                                             alt={user_obj.user.slack_username}/>
                                        <strong className="mr-auto mt-2 mb-2">{user_obj.user.slack_username}</strong>
                                        <small>{this.timeDifference(user_obj.opened_time)}</small>
                                    </Toast.Header>
                                )
                                }
                            </Toast>
                        </div>
                    </div>
                    : ""
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapDispatchToProps = dispatch => ({
    getActiveUsers: () => dispatch(getActiveUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUsers)