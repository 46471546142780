import rootReducer from '../reducers';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';


export const history = createBrowserHistory();

export default function configureStore(initialState) {
    /**
     * This is the default config for the configureStore
     **/

    const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['users', 'router']
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer(history));

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    let store = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                thunk,
                routerMiddleware(history)
            )
        ),
    );
    let persistor = persistStore(store);

    return {store, persistor}

}