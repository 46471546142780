import axios from "axios";

export function dataHasErrored(bool, error_details) {
    return {
        type: 'USERS_DATA_HAS_ERRORED',
        hasErrored: bool,
        errorDetails: error_details
    };
}

export function dataIsLoading(bool) {
    return {
        type: 'USERS_DATA_IS_LOADING',
        isLoading: bool
    };
}

export function dataFetchSuccess(data) {
    return {
        type: 'USERS_DATA_FETCH_SUCCESS',
        data: data
    };
}

export function getActiveUsers() {
    return (dispatch, getState) => {
        dispatch(dataIsLoading(true));

        let auth_token = getState().authToken.token;

        const endpoint = `${process.env.REACT_APP_BACKEND_URL}rest/v0.1/opened_doors/?minutes=320&distinct=1`;
        axios.get(endpoint, {'headers': {'Authorization': "Token " + auth_token}})
            .then(function (response) {
                dispatch(dataFetchSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(dataHasErrored(true, error))
            });
    }
}
