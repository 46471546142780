import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import configureStore, {history} from "./store/config/configureStore";
import {ConnectedRouter} from 'connected-react-router';


let persistent_storage_object = configureStore();

ReactDOM.render(
    <BrowserRouter>
        <Provider store={persistent_storage_object.store}>
            <ConnectedRouter history={history}>
                <PersistGate loading={null} persistor={persistent_storage_object.persistor}>
                    <Switch>
                        <Route exact path="/" component={App}/>
                    </Switch>
                </PersistGate>
            </ConnectedRouter>
        </Provider>
    </BrowserRouter>
    , document.getElementById('root'));
serviceWorker.register();
