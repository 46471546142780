const authToken = (state = {}, action) => {
    switch (action.type) {
        case 'ADD_AUTH_TOKEN':
            return {
                token: action.token,
                valid_until: action.valid_until,
            };
        case 'REMOVE_AUTH_TOKEN':
            return {};
        default:
            return state
    }
};

export default authToken